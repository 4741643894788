.gantt-parent {
    position: relative;
    display: grid;
    grid-template-columns: 120px 200px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px;
    grid-template-rows: auto minmax(48px, auto) minmax(48px, auto) minmax(48px, auto) minmax(48px, auto) minmax(48px, auto) minmax(48px, auto) minmax(48px, auto);
    overflow-x: scroll;
    /* border-top: 1px solid rgba(199, 199, 199, 0.25);
    border-left: 1px solid rgb(199, 199, 199, 0.25); */
}

.gantt-parent::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
}

.gantt-parent::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.gantt-y {
    position: -webkit-sticky;
    position: sticky !important;
    left: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(235, 235, 235, 0.6);
    border-right: 1px solid rgba(235, 235, 235, 0.6);
    padding: 0.5rem;
    z-index: 999;
}

.gantt-y:nth-child(odd) {
    background-color: #FBFBFB;
}

.gantt-y:first-child {
    border-bottom: none;
    border-right: none;
    background-color: #fff;
    padding: none !important;
}

.gantt-x {
    grid-row: 1;
    grid-column: 2;
    display: grid;
    grid-template-columns: 200px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px;
    grid-template-rows: 24px;
}

.gantt-body {
    grid-row: 1 / 3;
    grid-column: 2;
    z-index: 999;
}

.time-frame {
    font-size: 0.8rem;
}

.time-frame.now {
    background-color: rgba(165, 165, 180, 0.103);
}

.gantt-row {
    grid-row: 2;
    grid-column: 2;
    display: grid;
    grid-template-columns: 200px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px;
    grid-auto-rows: 48px;
}

.gantt-skeleton-x {
    border-right: 1px solid rgba(235, 235, 235, 0.6);
}

.gantt-skeleton-x.now {
    background-color: rgba(165, 165, 180, 0.103) !important;
}

.gantt-skeleton-y {
    border-bottom: 1px solid rgba(235, 235, 235, 0.6);
}

.gantt-skeleton-y:nth-child(odd) {
    background-color: #818a8f0a;
}

/* .gantt-skelton:nth-child(odd){
    background-color: rgba(248, 248, 248, 0.658);
} */

.gantt-item {
    position: relative;
    background-color: #E8EFF6;
    border-left: 6px solid #D9E1E9;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.20rem 0.25rem 0.20rem 0.5rem;
    margin-bottom: 2px;
    line-height: 0.95rem;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    overflow: hidden;
}

.gantt-item.success {
    border-color: var(--success);
}

.gantt-item.primary {
    border-color: var(--primary);
}

.gantt-item.warning {
    border-color: var(--warning);
}

.gantt-item:hover {
    text-decoration: none;
    opacity: 0.8;
}

.gantt-item-small {
    font-size: 0.6rem;
    line-height: 0.7rem;
}

.gantt-item:last-child {
    margin-bottom: 0rem !important;
}